import React from 'react'
import format from 'date-fns/format'
import { RiFacebookFill, RiBehanceFill, RiVimeoFill, RiMailFill } from "react-icons/ri";
import { Circle } from '@chakra-ui/react';
import { Link } from 'gatsby';

import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Text from '../../components/Text'
import Button from '../../components/Button';
import BackgroundImage from '../../components/BackgroundImage';
import { responsive } from '../../contexts/responsive'

import Logo from '../Layout/Logo'
// import useHeader from '../../contexts/header/useHeader'
import doorLogo from './re_door.png'

const socialMedia = [
  {
    Icon: RiFacebookFill,
    trackerName: 'footer_facebook',
    href: 'https://www.facebook.com/ReLAB.cc/'
  },
  {
    Icon: RiBehanceFill,
    trackerName: 'footer_behance',
    href: 'https://www.behance.net/Re-LAB'
  },
  {
    Icon: RiVimeoFill,
    trackerName: 'footer_viemo',
    href: 'https://vimeo.com/relab'
  },
  {
    Icon: RiMailFill,
    trackerName: 'footer_collab',
    to: '/contact'
  },
]

const Footer = (props) => {
  // const { headerHeight } = useHeader()
  return (
    <Flex
      height="inherit"
      color="white"
      bg="black"
      flexDirection={responsive('column', 'row')}
      pt={responsive('4.125em', '2.25em')}
      pb={responsive('2.75em', '3em')}
      px={responsive('2.625em')}
      justifyContent={responsive('flex-start', 'space-between')}
      alignItems={responsive('flex-start', 'center')}
      {...props}
    >
      <Box width={responsive('100%', '50%')}>
        <Box width={['73%', '40%', '33%']}>
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <Text fontSize={['4.5vw', '1.25em', '1.5em']}>from CONTENT to CONTEXT</Text>
      </Box>
      <Flex mt={responsive('1.5em', '0')} alignItems={responsive('flex-start', 'flex-end')} flexDirection={responsive('column', 'row')}>
        <Box mr={responsive('0', '1.75em')} width={responsive('100%', '8.625rem')}>
          <Circle
            as={Button.Transparent}
            mx={responsive('0', 'auto')}
            px="1.5em"
            mb={responsive('0.875rem', '0.375em')}
            border="1px solid"
            href="https://door.relab.cc/"
            target={'_blank'}
            _hover={{ color: 'custom.bgGray' }}
            trackerName={'footer_infodoor'}
            size={responsive('7em', '9.375em')}
          >
            <Box width={responsive('3.875em', '5.125em')}>
              <BackgroundImage src={doorLogo} ratio={1} />
            </Box>
          </Circle>
          <Text
            letterSpacing="0.02em"
            textAlign={responsive('left', 'center')}
            fontSize={'0.875em'}
          >點我看看 RE:LAB 的靈感都從何而來</Text>
        </Box>
        <Box mt={responsive('1.25rem', 0)}>
          <Flex mb="1em">
            {socialMedia.map(({ Icon, href, to, trackerName }, i) => (
              <Circle ml={i && '0.875rem'} border="1px solid" size={responsive('3.125rem', '4rem')} key={i}>
                <Button.Icon trackerName={trackerName} icon={<Icon size="1.75rem" />} to={to} href={href} />
              </Circle>
            ))}
          </Flex>
          <Text fontSize={responsive('0.75em', '1em')}>1F., No. 54, Aly. 9, Ln. 181, Jiaxing St., Xinyi Dist., Taipei City 11053, Taiwan</Text>
          <Text fontSize={responsive('0.75em', '1em')} mt="1.375em">Copyright © {format(new Date(), 'yyyy')}. All rights reserved.</Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Footer
