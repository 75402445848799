import React from 'react'
import SVG from '../../components/SVG'

const Logo = (props) => {
  return (
    <SVG viewBox="0 0 155 32" {...props}>
      <g>
        <path d="M17.66,31.41,11.36,18a.17.17,0,0,0-.18-.13H4.7a.16.16,0,0,0-.18.18V31.27a.4.4,0,0,1-.45.45H.65a.4.4,0,0,1-.45-.45V.67A.4.4,0,0,1,.65.22H12.76a9.73,9.73,0,0,1,4.75,1.13A7.94,7.94,0,0,1,20.68,4.5a9.48,9.48,0,0,1,1.13,4.68,8.59,8.59,0,0,1-1.65,5.33,8.08,8.08,0,0,1-4.57,3c-.12.06-.15.13-.09.22L22,31.18a.52.52,0,0,1,0,.23c0,.21-.12.31-.36.31H18.16A.5.5,0,0,1,17.66,31.41ZM4.52,4.14V14.22a.16.16,0,0,0,.18.18h7.52A5.23,5.23,0,0,0,16,13a5,5,0,0,0,1.44-3.76A5.16,5.16,0,0,0,16,5.4,5.16,5.16,0,0,0,12.22,4H4.7A.16.16,0,0,0,4.52,4.14Z" fill="currentColor"/>
        <path d="M48.27,4h-16a.16.16,0,0,0-.18.18v9.67a.16.16,0,0,0,.18.18H45.82a.4.4,0,0,1,.45.45v2.84a.4.4,0,0,1-.45.45H32.29a.16.16,0,0,0-.18.18v9.9a.16.16,0,0,0,.18.18h16a.4.4,0,0,1,.45.45v2.83a.4.4,0,0,1-.45.45h-20a.4.4,0,0,1-.45-.45V.67a.4.4,0,0,1,.45-.45h20a.4.4,0,0,1,.45.45V3.51A.4.4,0,0,1,48.27,4Z" fill="currentColor"/>
        <path d="M77.75,31.27V.67A.4.4,0,0,1,78.2.22h3.42a.4.4,0,0,1,.45.45V27.81a.16.16,0,0,0,.18.18H97.78a.4.4,0,0,1,.45.45v2.83a.4.4,0,0,1-.45.45H78.2A.4.4,0,0,1,77.75,31.27Z" fill="currentColor"/>
        <path d="M123.47,31.36,121,23.27a.17.17,0,0,0-.18-.13H109.93a.17.17,0,0,0-.18.13l-2.52,8.09a.52.52,0,0,1-.54.36H103c-.33,0-.45-.16-.36-.49L112.54.58a.52.52,0,0,1,.54-.36h4.5a.52.52,0,0,1,.54.36l9.94,30.65.05.18c0,.21-.14.31-.41.31H124A.52.52,0,0,1,123.47,31.36ZM110.9,19.65a.18.18,0,0,0,.15.07h8.58a.21.21,0,0,0,.16-.07.13.13,0,0,0,0-.16L115.46,5.62a.14.14,0,0,0-.13-.09.15.15,0,0,0-.14.09l-4.32,13.87A.12.12,0,0,0,110.9,19.65Z" fill="currentColor"/>
        <path d="M150,15.57q4.77,2,4.77,7.51a7.8,7.8,0,0,1-2.75,6.39,10.91,10.91,0,0,1-7.15,2.25H133.69a.4.4,0,0,1-.45-.45V.67a.4.4,0,0,1,.45-.45h10.85q4.68,0,7.26,2.14a7.59,7.59,0,0,1,2.59,6.23A6.7,6.7,0,0,1,150,15.3Q149.81,15.44,150,15.57ZM137.56,4.14v9.49a.16.16,0,0,0,.18.18h6.8a6,6,0,0,0,4.11-1.3A4.45,4.45,0,0,0,150.16,9a4.62,4.62,0,0,0-1.51-3.64A5.92,5.92,0,0,0,144.54,4h-6.8A.16.16,0,0,0,137.56,4.14ZM149,26.53a5.06,5.06,0,0,0,1.51-3.85A5,5,0,0,0,149,18.83a5.91,5.91,0,0,0-4.19-1.42h-7a.16.16,0,0,0-.18.18V27.76a.16.16,0,0,0,.18.18h7.16A5.72,5.72,0,0,0,149,26.53Z" fill="currentColor"/>
      </g>
      <g>
        <path d="M63.38,19.28a6.25,6.25,0,1,0,6.25,6.25A6.25,6.25,0,0,0,63.38,19.28Zm0,9.91A3.66,3.66,0,1,1,67,25.53,3.66,3.66,0,0,1,63.38,29.19Z" fill="currentColor"/>
        <circle cx="63.38" cy="7.35" r="5.73" fill="currentColor"/>
      </g>
    </SVG>
  )
}

export default Logo
