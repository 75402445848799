import React from 'react'
import { IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi'

import Box from '../components/Box';
import Flex from '../components/Flex';
import Logo from '../components/Logo';
import Link from '../components/Link';
import Menu from './Menu';
import { Media } from '../contexts/responsive';

const Header = ({ mode, pathname, ...props }) => {
  const isSolid = mode === 'solid'
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex
      as="header"
      position="fixed"
      top={0}
      left={0}
      right={0}
      alignItems="center"
      zIndex="overlay"
      boxShadow="md"
      bg={isSolid ? 'white' : 'transparent'}
      color={isSolid ? 'black' : 'white'}
      transition="background 500ms, color 500ms"
      {...props}
    >
      <Box px="1.25em">
        <Link to="/">
          <Logo display="block" width="6rem" />
        </Link>
      </Box>
      <Box flexGrow="1" />
      <Media lessThan='md'>
        <Box px="0.5em">
          <IconButton
            color="inherit"
            colorScheme="whiteAlpha"
            bg="transparent"
            px="0"
            height="100%"
            fontSize="1.5em"
            icon={<FiMenu />}
            onClick={onOpen}
          />
        </Box>
      </Media>
      <Menu isOpen={isOpen} onClose={onClose} pathname={pathname} isSolid={isSolid} />
    </Flex>
  )
}

export default Header
