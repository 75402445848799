import { css } from '@emotion/react'
import "focus-visible/dist/focus-visible"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { mediaStyle } from './src/contexts/responsive'

export default css`
  body {
    min-width: 100%;
    min-height: 100%;
  }

  @media screen and (min-width: 992px) {
    body {
      font-size: calc(min(100vw, 1920px) / 120);
    }
  }

  ::-moz-selection {
    color: white;
    background: #222;
  }

  ::selection {
    color: white;
    background: #222;
  }

  mark {
    background-color: rgb(255, 175, 92);
  }
  ${mediaStyle}
`;
